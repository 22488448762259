.socialBar{
    margin:auto;
    margin-top:-20px;
    width:115px;
    height:50px;
    position:relative;
    z-index:1;
}
@keyframes socialMedia{
    0%{
        width:115px;
    }
    100%{
        width:300px;
    }
}
.socialBar:hover{
    animation-name:socialMedia;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    width:300px;
}
.media{
    height:35px;
    width:35px;
    border-radius:10px;
    position:absolute;
    padding:2px;
    background-color:white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;;
}
.media:hover{
    border:1px solid black;
}

.fb{
    position:absolute;
    left:0px;
    top:-5px;
}
.yt{
    position:absolute;
    left:20%;
    top:0px;
}
.git{
    z-index:1;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.insta{
    position:absolute;
    top:0px;
    right:20%;
    z-index:-1;
}
.twit{
    position:absolute;
    right:0px;
    top:-5px;
    z-index:-2;
}