.contactForm{
    max-width:500px;
    border:1px solid rgb(11, 11, 11);
    border-radius:10px;
    margin-top:20px;
    margin-bottom:20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.contactForm input, .contactForm textarea{
    background-color: white;
    border: 1px solid rgb(75, 139, 211);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 1px 10px;
}
.contactForm textarea{
    min-height:100px;
}