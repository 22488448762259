.progressBar{
    background-color:lightgrey;
    width:100%;
    height:10px;
    border-radius:20px;
    overflow:hidden;
    margin-bottom:10px;
}
.progressIndicator{
    background-color:aqua;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}