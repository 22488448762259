@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.projectCard {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    min-height: 310px;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.card-title {
    font-size: 15px;
    text-align: justify;
    text-justify: none;
    font-weight: 600;
}

.card:hover {
    border: 1px solid black;
}

.card-img-top {
    position: relative;
    height: auto;
}

.projectType {
    position: absolute;
    left: -20px;
    top: 0px;
    background-color: #0099ff;
    color: white;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.projectType:hover {
    color: black;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}




/*For profile cards*/
.profileCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    line-height: 100%;
    font-size: 40px;
}

.profileCards .profile {
    border-radius: 100%;
    min-height: 300px;
    border: 2px solid black;
    border-style: dashed;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.greetings {
    font-size: 20px;
    margin-bottom: 0px;
}

.B {
    color: blue;
}

.T {
    color: lightgreen;
}

.domain {
    font-size: 15px;
}

@keyframes cursor-blink {
    0% {
        opacity: 0;
    }
}

.writingAnimation {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fce055;
    text-align: center;
    background: linear-gradient(45deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(0, 183, 252, 1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    height: 40px;
    font-size: 2rem;
}

.writingAnimation::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 40px;
    background: black;
    display: inline-block;
    animation: cursor-blink 1.5s steps(2) infinite;
}


@keyframes lazy1 {
    0% {
        background-color:#B4B4B8;
    }

    50% {
        background-color:#C7C8CC;
    }
    100%{
        background-color:#B4B4B8;
    }
}

@keyframes lazy2 {
    0% {
        background-color:#A9A9A9;
    }

    50% {
        background-color:#B4B4B3;
    }
    100%{
        background-color:#A9A9A9;
    }
}


.lazy_projectType {
    position: absolute;
    left: -20px;
    top: 0px;
    background-color:grey;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    height:30px;
    width:75%;
}

.lazy_projectCard {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    background-color: #b7b5b5;
    min-height: 310px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.lazy_image {
    animation-name: lazy1;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    min-height: 13rem;
    border:1px solid lightgrey;
}
.lazy_date{
    padding:5px;
    border-radius:10px;
    width:10rem;
    height:1.5rem;
    background-color: grey;
}
.lazy_title{
    background-color:grey;
    height:25px;
    width:100%;
    border-radius:10px;
}
.user_img{
    border-radius:100%;
    border:1px solid rgb(92, 91, 91);
}
.testinomialText{
    font-weight:400;
    text-align:justify;
}