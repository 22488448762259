.navbar_logo{
    height:2.5rem;
    width:2.5rem;
    border-radius:100%;
}
.navbar{
    border-bottom:1px solid rgb(171, 168, 168);
    margin-bottom:4px;
}
.navbar_logo:hover{
    border:1px solid black;
}
.nav_item{
    min-width:30px;
}
.active{
    font-weight:800;
}
.nav-item:hover{
    border-bottom:2px solid red;
}
.login, .signup{
    text-decoration:none;
    font-size:18px;
}
.login:hover, .signup:hover{
    border-bottom:2px solid red;
}

.footer{
    background: linear-gradient(to top, #ace9fe 0%, #bac7f4 100%);
}
.footerLogo{
    height:50px;
    width:50px;
    border:1px solid black;
    border-radius:100%;
}
.footerCopyRight{
    padding-top:30px;
    display:flex;
    justify-content: center;
    align-items:center;
}