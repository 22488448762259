.authenticationModal {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    margin: auto;
    min-width: 350px;
    max-width: 500px;
    border-radius: 10px;
    background-color: rgba(218, 218, 243, 0.95);
    border: 1px solid rgb(107, 107, 107);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.modalHeader {
    margin-bottom: 0px;
    padding-bottom: 3px;
    border-bottom: 1px solid rgb(246, 4, 4);
}

.modalBody {
    margin-top: 20px;
}

.modalBody input {
    background-color: white;
    border: 1px solid rgb(75, 139, 211);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 1px 10px;
}

.modalFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.loginBtn {
    width: 90%;
    max-width: 250px;
    margin-bottom: 0px;
    font-size:20px;
    font-weight:500;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.loginBtn:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.forgotPassword {
    font-weight: 600;
    margin-top:-10px;
}

.forgotPassword:hover {
    text-decoration: underline;
    cursor: pointer;
}
.newAccount{
    background-color: rgb(3, 165, 3);
    width: 90%;
    max-width: 250px;
    margin-bottom: 0px;
    color:white;
    font-size:20px;
    font-weight:500;
    border:none;
    padding:3px;
    border-radius:5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.newAccount:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.form-label{
    font-weight:500;
    font-size:15px;
}