.aboutCard{
    min-height:400px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.aboutCard:hover{
    border:1px solid rgb(78, 196, 247);
}
.imgContainer{
    display:flex;
    justify-content: center;
    align-items: center;
}
.aboutParagraph{
    text-align:justify;
    height:200px;
    overflow-y:scroll;
    padding:10px;
}
/* Set scrollbar width */
.aboutParagraph::-webkit-scrollbar {
    width: 3px;
}
/* Set background color for the track (progress bar) */
.aboutParagraph::-webkit-scrollbar-track {
    background: lightgrey;
}
/* Set color for the scroll thumb (draggable handle) */
.aboutParagraph::-webkit-scrollbar-thumb {
    background-color: rgb(255, 0, 0);
    border-radius: 20px;
    /* border: 3px solid orange; Creates padding around the scroll thumb */
}